import React from 'react';
import './Resources.css';
import { Databases } from '../Links';
import Footer from '../Footer';
import { Assets } from '../Assets';

function DatabasesPage() {
    const fontSize = "17px"; // for some reason my css stopped working so I had to use inline styling

    return (
        <>
            <div class="page-container">
                <h1 class="section-title">Databases</h1>

                <div class="database-item">
                    <img src={Assets.images.oprlm_db} alt="Database" loading="lazy" class="database-img"/>
                    <div>
                        <a href={Databases.OPRLM}><h3>OPRLM (Orientations of Proteins in Realistic Lipid Membranes) </h3> </a>
                        <p class="database-text" style={{fontSize: fontSize}}>
                            The OPRLM database provides experimental 3D structures from the RCSB Protein Data bank 
                            (PDB) of transmembrane TM and peripheral membrane proteins and membrane-active 
                            peptides. These structures are oriented within lipid membranes using the PPM method. OPRLM offers 
                            structural classification of proteins into families and superfamilies and 
                            various browsing, searching, sorting, and visualization options, such as 
                            displaying images simultaneously for all proteins from specific families, 
                            superfamilies, or membranes. It also provides information on protein topology, preferred 
                            intracellular localization, and allows for interactive visualization by Jmol, GLmol, LiteMol, 
                            and iCn3D. </p>
                    </div>
                </div>
                <div class="database-item">
                    <img src={Assets.images.MEMBRANOME} alt="Database" loading="lazy" class="database-img"/>
                    <div>
                        <a href={Databases.MEMBRANOME}><h3>MembranomeX</h3></a>
                        <p className="database-text" style={{fontSize: fontSize}}>
                        The MembranomeX database gathers comprehensive structural and functional data on single-pass transmembrane 
                        (bitopic) proteins from twenty different organisms. It provides 3D models
                        of bitopic proteins and their complexes generated by AlphaFold2 and AlphaFold3, along with
                        linking to the corresponding PDB entries and models of transmembrane helical dimers produced
                        using TMDOCK. Structures of full-length protein monomers from the AlphaFold Protein
                        Structure Database are parsed and reassembled in membranes using custom software. Protein
                        models are oriented within the lipid bilayer by the PPM method. MembranomeX includes
                        protein classification, amino acid sequences, topologies, and intracellular localizations sourced
                        from UniProt and InterPro. Additionally, it features experimentally supported protein-protein
                        interactions from HINT and APID, lists of known protein complexes from Reactome, Complex
                        Portal, CORUM, and PDB, and associated pathways from KEGG. The database also provides
                        mutagenesis data from MutDB and links to related bioinformatics resources. The database
                        offers tools for advanced search, protein network analysis, and structural visualization to
                        facilitate comparative analysis of bitopic proteins. </p>
                    </div>
                </div>
                <div class="database-item">
                    <img src={Assets.images.permm_db} alt="Database" loading="lazy" class="database-img"/>
                    <div>
                        <a href={Databases.PERMM}><h3>PerMM (Permeability of Molecules across Membranes)</h3></a>
                        <p className="database-text" style={{fontSize: fontSize}} >
                            The PerMM database compiles both experimental and calculated permeability coefficients of
                            drug-like molecules in artificial and natural membranes, such as BLM, PAMPA, Caco-2/MDCK
                            cells, and the BBB. This information as accompanied by references and additional related data.
                            Currently, the database includes 507 molecules, ranging from small organic compounds to
                            natural products of diverse chemical structures. It offers downloadable coordinate files and
                            interactive visualizations to depict the optimal localization and translocation pathways of these
                            molecules across lipid bilayers. This information aids in assessing the contribution of passive
                            diffusion to the transport of drugs and other small molecules across biological membranes.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default DatabasesPage;

